import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import React from 'react';
import Alerts from '../../../components/Alert';
import Api from '../../../connections/Api';
import DataEmpty from './DataEmpty';

import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DataGrid, GridCsvExportMenuItem, GridToolbarContainer } from '@mui/x-data-grid';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import { DateTime } from 'luxon';
import { orderByName } from '../../../functions';
import * as S from './Styles';

function Accounting() {
  const theme = useTheme();
  const options = ['Comandas', 'Pedidos', 'Canceladas', 'Gorjetas'];

  const [data, setData] = React.useState(null);
  const [groupList, setGroupList] = React.useState(['Comandas']);
  const [finalDate, setFinalDate] = React.useState(new Date());
  const [initialDate, setInitialDate] = React.useState(new Date());
  const [alert, setAlert] = React.useState({ open: false, message: '' });
  const [showEmployeeList, setShowEmployeeList] = React.useState(false);
  const [employeeSelected, selectEmployee] = React.useState('Todos');
  const [employeeOptions, setEmployeeOptions] = React.useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      const employeesList = await Api.get('/challenge/able/employees');

      const employeesListResult = employeesList.map(({ name }) => name).sort(orderByName);

      setEmployeeOptions(['Todos', ...employeesListResult]);
    };

    fetchData();
  }, []);

  const handleClose = () => setAlert({ ...alert, open: false });

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      initialDate.setHours(0, 0, 0, 0);
      finalDate.setHours(23, 59, 59, 0);

      const initial = initialDate.getTime();
      const final = finalDate.getTime();

      if (final < initial) {
        setAlert({
          ...alert,
          open: true,
          status: 'error',
          message: 'A data final não pode ser menor que a data inicial',
        });
      } else {
        const bodyData = {
          initialDate: initial,
          finalDate: final,
        };

        if (groupList[0] === 'Comandas') {
          const data = await Api.post('/accounting/report', bodyData);
          setData(data);
        } else if (groupList[0] === 'Canceladas') {
          const data = await Api.post('/accounting/canceled-guides-report', bodyData);
          setData(data);
        } else if (groupList[0] === 'Pedidos') {
          const data = await Api.post('/accounting/detailed-report', bodyData);
          setData(data);
        } else if (groupList[0] === 'Gorjetas') {
          const data = await Api.post('/accounting/getTips', {
            ...bodyData,
            filter: employeeSelected.trim(),
          });
          setData(data);
        }
      }
    } catch ({ message }) {
      setAlert({
        ...alert,
        open: true,
        status: 'error',
        message,
      });
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridCsvExportMenuItem
          options={{
            fileName: `relatorio-${DateTime.now().toFormat('dd-MM-yyyy')}`,
            delimiter: ';',
            utf8WithBom: true,
            fields: ['id', 'table', 'created_at', 'closed_by', 'ordersTotalNum'],
          }}
        />
      </GridToolbarContainer>
    );
  }

  const handleChangeEmployeeSelect = ({ target }) => {
    selectEmployee(target.value);
  };

  function handleChangeSelect({ target: { value } }) {
    setShowEmployeeList(false);

    if (value === 'Gorjetas') {
      setShowEmployeeList(true);
    }

    setGroupList(typeof value === 'string' ? value.split(',') : value);
  }

  function renderTable() {
    if (['Comandas', 'Gorjetas'].includes(groupList[0]) && 'table' in data[0]) {
      return (
        <S.Container style={{ height: '100%' }}>
          <DataGrid
            columns={[
              {
                field: 'id',
                headerName: 'Nº da comanda',
                minWidth: 150,
                flex: 1,
                headerAlign: 'center',
                align: 'center',
              },
              {
                field: 'table',
                headerName: 'Mesa',
                minWidth: 150,
                flex: 1,
                headerAlign: 'center',
                align: 'center',
              },
              {
                field: 'created_at',
                headerName: 'Aberta em:',
                minWidth: 150,
                flex: 1,
                headerAlign: 'center',
                align: 'center',
              },
              {
                field: 'closed_by',
                headerName: 'Fechada por:',
                minWidth: 150,
                flex: 1,
                headerAlign: 'center',
                align: 'center',
              },
              {
                field: 'ordersTotal',
                headerName: 'Total',
                minWidth: 150,
                flex: 1,
                headerAlign: 'center',
                align: 'center',
              },
              {
                field: 'ordersTotalNum',
                headerName: 'Total (para exportação)',
                minWidth: 150,
                flex: 1,
                type: 'number',
                headerAlign: 'center',
                align: 'center',
                hide: true,
              },
            ]}
            rows={data}
            autoHeight
            disableSelectionOnClick
            components={{ Toolbar: CustomToolbar }}
            localeText={{ toolbarExportCSV: 'Exportar relatório' }}
          />
        </S.Container>
      );
    }
    if (groupList[0] === 'Pedidos' && 'amount' in data[0]) {
      return (
        <S.Container style={{ height: '100%' }}>
          <DataGrid
            columns={[
              {
                field: 'id',
                headerName: 'Nome do Produto',
                minWidth: 150,
                flex: 1,
                headerAlign: 'center',
                align: 'center',
              },
              {
                field: 'amount',
                headerName: 'Quantidades',
                minWidth: 150,
                flex: 1,
                headerAlign: 'center',
                align: 'center',
              },
              {
                field: 'group',
                headerName: 'Categoria',
                minWidth: 150,
                flex: 1,
                headerAlign: 'center',
                align: 'center',
              },
              {
                field: 'ordersTotal',
                headerName: 'Total',
                minWidth: 150,
                flex: 1,
                headerAlign: 'center',
                align: 'center',
              },
              {
                field: 'ordersTotalNum',
                headerName: 'Total (para exportação)',
                minWidth: 150,
                flex: 1,
                type: 'number',
                headerAlign: 'center',
                align: 'center',
                hide: true,
              },
            ]}
            rows={data}
            autoHeight
            disableSelectionOnClick
            components={{ Toolbar: CustomToolbar }}
            localeText={{ toolbarExportCSV: 'Exportar relatório' }}
          />
        </S.Container>
      );
    }
    if (groupList[0] === 'Canceladas' && 'table' in data[0]) {
      return (
        <S.Container style={{ height: '100%' }}>
          <DataGrid
            columns={[
              {
                field: 'id',
                headerName: 'Nº da comanda',
                minWidth: 150,
                flex: 1,
                headerAlign: 'center',
                align: 'center',
              },
              {
                field: 'table',
                headerName: 'Mesa',
                minWidth: 150,
                flex: 1,
                headerAlign: 'center',
                align: 'center',
              },
              {
                field: 'created_at',
                headerName: 'Comanda aberta em:',
                minWidth: 150,
                flex: 1,
                headerAlign: 'center',
                align: 'center',
              },
              {
                field: 'closed_by',
                headerName: 'Descrições adicionais',
                minWidth: 150,
                flex: 1,
                headerAlign: 'center',
                align: 'center',
              },
              {
                field: 'ordersTotal',
                headerName: 'Total',
                minWidth: 150,
                flex: 1,
                headerAlign: 'center',
                align: 'center',
              },
              {
                field: 'ordersTotalNum',
                headerName: 'Total (para exportação)',
                minWidth: 150,
                flex: 1,
                type: 'number',
                headerAlign: 'center',
                align: 'center',
                hide: true,
              },
            ]}
            rows={data}
            autoHeight
            disableSelectionOnClick
            components={{ Toolbar: CustomToolbar }}
            localeText={{ toolbarExportCSV: 'Exportar relatório' }}
          />
        </S.Container>
      );
    }
  }

  return (
    <>
      <S.Wrapper onSubmit={handleSubmit} component="form" xs={12} sm={3} md={2}>
        <Alerts
          open={alert.open}
          status={alert.status}
          message={alert.message}
          handleClose={handleClose}
        />
        <h2>Contabilidade</h2>
        <S.Container container>
          <S.Row item>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                required
                value={initialDate}
                inputFormat="dd/MM/yyyy"
                label="Período inicial"
                onChange={(currentDate) => setInitialDate(currentDate)}
                renderInput={(props) => <TextField fullWidth margin="dense" {...props} />}
              />
            </LocalizationProvider>
          </S.Row>
          <S.Row item>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                required
                value={finalDate}
                inputFormat="dd/MM/yyyy"
                label="Período final"
                onChange={(currentDate) => setFinalDate(currentDate)}
                renderInput={(props) => <TextField fullWidth margin="dense" {...props} />}
              />
            </LocalizationProvider>
          </S.Row>
          <S.Row item>
            <FormControl margin="dense" style={{ width: 150 }}>
              <InputLabel id="group-label">Agrupar por:</InputLabel>
              <Select
                labelId="group-label"
                id="group"
                name="group"
                required
                value={groupList}
                onChange={(event) => handleChangeSelect(event)}
                input={<OutlinedInput label="Agrupar por:" />}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 48 * 4.5 + 8,
                      width: 150,
                    },
                  },
                }}
              >
                {options.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={{
                      fontWeight:
                        groupList.indexOf(name) === -1
                          ? theme.typography.fontWeightRegular
                          : theme.typography.fontWeightMedium,
                    }}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </S.Row>
          {showEmployeeList && (
            <S.Row item>
              <FormControl margin="dense" fullWidth style={{ minWidth: 300 }}>
                <InputLabel id="group-label">Funcionários</InputLabel>
                <Select
                  labelId="group-label"
                  id="employee"
                  name="employee"
                  required
                  value={employeeSelected}
                  onChange={handleChangeEmployeeSelect}
                  input={<OutlinedInput label="Funcionários" />}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 48 * 4.5 + 8,
                        width: 150,
                      },
                    },
                  }}
                >
                  {employeeOptions.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      style={{
                        fontWeight:
                          [employeeSelected].indexOf(name) === -1
                            ? theme.typography.fontWeightRegular
                            : theme.typography.fontWeightMedium,
                      }}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </S.Row>
          )}

          <S.Row item>
            <Button
              type="submit"
              variant="contained"
              style={{ height: 55, marginTop: 8 }}
            >
              Consultar
            </Button>
          </S.Row>
          {data === null ? null : (
            <S.Row style={{ height: 55, marginTop: 8 }}>
              <h3 style={{ fontWeight: 'normal' }}>Soma total das comandas:</h3>{' '}
              <h3>{data[data.length - 1].ordersTotal}</h3>
            </S.Row>
          )}
        </S.Container>
      </S.Wrapper>
      <S.Divider />
      {data === null ? <DataEmpty /> : renderTable()}
    </>
  );
}

export default Accounting;
