import { LoadingButton } from '@mui/lab';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItemText,
  TextField,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import queryString from 'query-string';
import React, { useRef } from 'react';
import NumberFormat from 'react-number-format';
import MaterialIcon from '../../../../../components/MaterialIcon';
import Api from '../../../../../connections/Api';
import {
  deepClone,
  isNotEmpty,
  orderByName,
  replaceSourcePath,
} from '../../../../../functions';

import * as S from './Styles';

const options = [
  'Espetinho',
  'Porções',
  'Pratos',
  'Bebidas',
  'Drinks',
  'Especial',
  'Bar',
];

const NumberFormatCustom = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            id: props.id,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="R$ "
    />
  );
});

const initialData = {
  name: '',
  price: 0,
  category: '',
  description: null,
  price_of: null,
  image: null,
  combos_released: 0,
  combo: [],
};

function CreateProduct({ open, successCreateItem, handleClosed, showError, reload }) {
  const theme = useTheme();

  const [data, setData] = React.useState(initialData);

  const [menu, setMenu] = React.useState([]);
  const [menuList, setMenuList] = React.useState([]);
  const [menuListRef, setMenuListRef] = React.useState([]);
  const [onReload, setReload] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [selectInFocus, setSelectInFocus] = React.useState(false);
  const [categoryList, setCategoryList] = React.useState([]);
  const [selectedFile, setSelectedFile] = React.useState(null);

  const inputFileRef = useRef(null);
  const handleClick = () => inputFileRef.current && inputFileRef.current.click();

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await Api.get('/menu/custom');
      const organizedResult = response.sort(orderByName);

      setMenuListRef(organizedResult);
      setMenuList(organizedResult.map((e) => e.name));
    };

    fetchData();
  }, [data]);

  React.useEffect(() => {}, [onReload]);

  const hasComplementName = (name) => (name.includes('Nº1') ? name : `${name} Nº1`);

  const createProduct = async (event, data) => {
    event.preventDefault();
    setLoading(true);

    const error = [];

    if (data.combos_released > 0 && data.combo.length === 0) {
      error.push(`Selecione os itens liberados para o combo`);
    }

    if (error.length > 0) {
      setLoading(false);
      showError(error[0]);

      return;
    }

    try {
      const bodyData = {
        name: data.combos_released > 0 ? hasComplementName(data.name) : data.name,
        price: data.price,
        image: data.image,
        price_of: data.price_of,
        category: data.category,
        description: data.description,
        combos_released: data.combos_released,
        updated_by: localStorage.getItem('user_id'),
        combo: data.combos_released > 0 ? JSON.stringify(data.combo) : [],
      };

      const formData = new FormData();
      formData.append('image', selectedFile);

      const { message } = await Api.post(
        `/menu/create?${queryString.stringify(bodyData)}`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } },
      );
      successCreateItem(message);
      setLoading(false);
      handleClosed();
      reload();
    } catch ({ message }) {
      setLoading(false);
      showError(message);
    }
  };

  const handleChange = ({ target: { id, value } }) => {
    const cloneData = deepClone(data);

    cloneData[id] = value || null;

    setData(cloneData);
  };

  const handleChangeSelect = ({ target: { name, value } }) => {
    setCategoryList(typeof value === 'string' ? value.split(',') : value);
    data[name] = value;
  };

  const handleFileSelect = ({ target }) => setSelectedFile(target.files[0]);

  const handleChangeMenu = ({ target: { name, value } }) => {
    setMenu(typeof value === 'string' ? value.split(',') : value);
    const comboList = menuListRef.filter((item) => value.includes(item.name));

    data[name] = comboList.map((item) => ({
      ...item,
      price: 0,
      amount: 1,
      description: ['(ITEM DE COMBO)', item.description]
        .filter((item) => isNotEmpty(item))
        .join(' - '),
    }));

    setReload(!onReload);
  };

  return (
    <Dialog open={open} onClose={handleClosed} fullScreen>
      <DialogTitle>Adicionar Produto</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Preencha os campos abaixo para adicionar um novo produto ao menu.
        </DialogContentText>
        <form id="formEdit" onSubmit={(e) => createProduct(e, data)}>
          <TextField
            autoFocus
            fullWidth
            id="name"
            type="text"
            margin="dense"
            required
            label="Nome do Produto"
            defaultValue={data.name}
            onChange={handleChange}
          />
          <div style={{ display: 'flex', justifyContent: 'Center' }}>
            <TextField
              id="price"
              label="Preço"
              margin="dense"
              required
              fullWidth
              onChange={handleChange}
              defaultValue={data.price}
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
            <S.Divider />
            <TextField
              id="price_of"
              label="Promoção"
              margin="dense"
              fullWidth
              onChange={handleChange}
              defaultValue={data.price_of}
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
          </div>

          <FormControl fullWidth margin="dense">
            <InputLabel id="category-label">
              {categoryList.length === 0
                ? !selectInFocus
                  ? ''
                  : 'Categoria'
                : 'Categoria'}
            </InputLabel>
            <Select
              labelId="category-label"
              id="category"
              name="category"
              displayEmpty
              onOpen={() => setSelectInFocus(true)}
              onClose={() => setSelectInFocus(false)}
              required
              margin="dense"
              value={categoryList}
              onChange={handleChangeSelect}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <em>Selecione uma categoria</em>;
                }

                return selected.join(', ');
              }}
              input={
                <OutlinedInput placeholder="https://exemplo.com.br" label="Categoria" />
              }
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 48 * 4.5 + 8,
                    width: 250,
                  },
                },
              }}
            >
              <MenuItem disabled value="">
                <em>Selecione uma categoria</em>
              </MenuItem>
              {options.map((name) => (
                <MenuItem
                  key={name}
                  value={name}
                  style={{
                    fontWeight:
                      categoryList.indexOf(name) === -1
                        ? theme.typography.fontWeightRegular
                        : theme.typography.fontWeightMedium,
                  }}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            fullWidth
            id="combos_released"
            type="number"
            margin="dense"
            required
            label="Combos liberados"
            defaultValue={data.combos_released}
            onChange={handleChange}
            placeholder="Insira a quantidade de combos liberados"
          />

          {data.combos_released > 0 && (
            <FormControl fullWidth margin="dense">
              <InputLabel id="combo_items">
                Selecione os itens liberados para o combo
              </InputLabel>
              <Select
                labelId="combo_items"
                id="combo"
                name="combo"
                multiple
                value={menu}
                onChange={handleChangeMenu}
                input={
                  <OutlinedInput label="Selecione os itens liberados para o combo" />
                }
                renderValue={(selected) => selected.join(', ')}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 48 * 4.5 + 8,
                      width: 250,
                    },
                  },
                }}
              >
                {menuList.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={menu.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <TextField
            type="text"
            margin="dense"
            id="description"
            fullWidth
            label="Descrição"
            onChange={handleChange}
            defaultValue={data.description}
          />

          <S.Uploader
            htmlFor="contained-button-file"
            style={{
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              ...(data?.image && {
                backgroundImage: `url(${replaceSourcePath(data.image)})`,
              }),
            }}
          >
            <S.Input
              ref={inputFileRef}
              accept="image/*"
              id="contained-button-file"
              multiple={false}
              onChange={handleFileSelect}
              type="file"
              style={{ display: 'none' }}
            />
            <S.Upload LinkComponent="span" variant="outlined" onClick={handleClick}>
              {selectedFile?.name
                ? selectedFile?.name
                : data.image
                  ? data.image.split('/')[2]
                  : 'Adicionar imagem'}
            </S.Upload>
          </S.Uploader>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={handleClosed}>
          Cancelar
        </Button>
        <LoadingButton
          type="submit"
          form="formEdit"
          endIcon={loading ? <MaterialIcon name="check" /> : null}
          loading={loading}
          loadingPosition="end"
          variant="contained"
        >
          {loading ? 'Adicionando...' : 'Adicionar'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default CreateProduct;
